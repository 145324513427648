(function ($) {

    var rxCamelCase = /-([a-z0-9_])/g;
    var rxCamelCaseFn = function(str, letter) {
        return letter.toUpperCase();
    };

    var CSSPrefix = '';
    var dashedCSSPrefix = '';
    var getComputedStyle = window.getComputedStyle;

    (function() {

        var rxPrefixes = /^(?:O|Moz|webkit|ms)|(?:-(?:o|moz|webkit|ms)-)/;

        //Detect prefix for current browser by finding the first property using a prefix.
        if(!getComputedStyle) {
            return;
        }

        var style = getComputedStyle(document.body, null);

        for(var k in style) {
            //We check the key and if the key is a number, we check the value as well, because safari's getComputedStyle returns some weird array-like thingy.
            CSSPrefix = (k.match(rxPrefixes) || (+k == k && style[k].match(rxPrefixes)));

            if(CSSPrefix) {
                break;
            }
        }

        //Did we even detect a prefix?
        if(!CSSPrefix) {
            CSSPrefix = dashedCSSPrefix = '';

            return;
        }

        CSSPrefix = CSSPrefix[0];

        //We could have detected either a dashed prefix or this camel Caseish-inconsistent stuff.
        if(CSSPrefix.slice(0,1) === '-') {
            dashedCSSPrefix = CSSPrefix;

            //There's no logic behind these. Need a look up.
            CSSPrefix = ({
                '-webkit-': 'webkit',
                '-moz-': 'Moz',
                '-ms-': 'ms',
                '-o-': 'O'
            })[CSSPrefix];
        } else {
            dashedCSSPrefix = '-' + CSSPrefix.toLowerCase() + '-';
        }
    })();


    window.Animayte = function (settings) {
        var _this = this;

        _this.options = $.extend({
            animAttrDelay: 'data-animate-delay',
            animAttrDuration: 'data-animate-duration',
            animAttrName: 'data-animate-name',
            animClassNameInit: 'animate-init',
            animClassNameDo: 'animate-do',
            animClassNameEnd: 'animate-end'
        }, settings);
    };

    Animayte.prototype._getAnimateElements = function (elems) {
        var _this = this;
        var templateSearch = '['+ _this.options.animAttrName + ']';
        return $(elems).filter(templateSearch).add($(elems).find(templateSearch));
    };

    Animayte.prototype._setStyle = function(el, prop, val) {
        var style = el.style;

        prop = prop.replace(rxCamelCase, rxCamelCaseFn).replace('-', '');

        if(prop === 'zIndex') {
            if(isNaN(val)) {
                //If it's not a number, don't touch it.
                //It could for example be "auto" (#351).
                style[prop] = val;
            } else {
                //Floor the number.
                style[prop] = '' + (val | 0);
            }
        }
        //64: "float" can't be set across browsers. Needs to use "cssFloat" for all except IE.
        else if(prop === 'float') {
            style.styleFloat = style.cssFloat = val;
        }
        else {
            //Need try-catch for old IE.
            try {
                //Set prefixed property if there's a prefix.
                if(CSSPrefix) {
                    style[CSSPrefix + prop.slice(0,1).toUpperCase() + prop.slice(1)] = val;
                }

                //Set unprefixed.
                style[prop] = val;

            } catch(ignore) {}
        }
    };

    Animayte.prototype._init = function (elem) {
        var _this = this;

        if (elem.hasClass(_this.options.animClassNameInit)){
            return;
        }
        if (elem.attr(_this.options.animAttrDuration)) {
            _this._setStyle(elem.get(0), 'animation-duration', elem.attr(_this.options.animAttrDuration));
        }
        if (elem.attr(_this.options.animAttrDelay)) {
            _this._setStyle(elem.get(0), 'animation-delay', elem.attr(_this.options.animAttrDelay));
        }


        elem.addClass(_this.options.animClassNameInit);

    };

    Animayte.prototype._clear = function (elem) {
        var _this = this;
        var animName = elem.attr(_this.options.animAttrName);
        if (animName) {
            elem
                .removeClass(animName)
                .removeClass(_this.options.animClassNameDo)
                .removeClass(_this.options.animClassNameEnd);
        }
    };
    Animayte.prototype._destroy = function (elem) {
        var _this = this;
        _this._clear(elem);
        elem.removeClass(_this.options.animClassNameInit);
        /*_this._setStyle(elem.get(0), 'animation-duration', null);
        _this._setStyle(elem.get(0), 'animation-delay', null);
        _this._setStyle(elem.get(0), 'animation-fill-mode', 'none');
        _this._setStyle(elem.get(0), 'visibility', 'hidden');*/
    };

    Animayte.prototype.init = function (elems) {
        var _this = this;
        _this._getAnimateElements(elems).each(function () {
            _this._init($(this));
        });
    };

    Animayte.prototype.start = function (elems) {
        var _this = this;
        _this._getAnimateElements(elems).each(function () {

            _this._init($(this));

            var animName = $(this).attr(_this.options.animAttrName);
            if (animName) {
                _this._clear($(this));
                $(this).addClass(animName).addClass(_this.options.animClassNameDo);
                $(this).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
                    $(this)
                        .removeClass(animName)
                        .removeClass(_this.options.animClassNameDo)
                        .addClass(_this.options.animClassNameEnd);
                });
            }
        });
    };

    Animayte.prototype.clear = function (elems) {
        var _this = this;
        _this._getAnimateElements(elems).each(function () {
            _this._clear($(this));
        });
    };

    Animayte.prototype.destroy = function (elems) {
        var _this = this;
        _this._getAnimateElements(elems).each(function () {
            _this._destroy($(this));
        });
    };
})(jQuery);