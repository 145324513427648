/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
import LazyLoad from 'lazyload';
// import {popper} from 'popper.js';
// import {tooltip} from 'tooltip.js';
// import {boostrap, popover} from 'bootstrap';
require('popper.js');
require('tooltip.js');
require('bootstrap');
window['Isotope'] = require('../../assets/plugins/isotope.pkgd.min.js');
require('../../assets/plugins/modernizr-custom');
require('../../assets/plugins/imagesloaded');
require('../../assets/plugins/animayte');
require('../../assets/plugins/slick');
require('../../assets/plugins/jquery.localScroll');
require('../../assets/plugins/jquery.matchHeight');
require('../../assets/plugins/jquery.scrollTo');

(function($) {

  /* Debounce */
  Function.prototype.debounce = function(threshold){
    var callback = this;
    var timeout;
    return function() {
      var context = this, params = arguments;
      window.clearTimeout(timeout);
      timeout = window.setTimeout(function() {
          callback.apply(context, params);
      }, threshold);
    };
  };


  function wrap_day_headers() {
    var $wrapper = $( '#tribe-events-content' );
    $wrapper.find( '.tribe-events-calendar th' ).each( function() {
      var $this = $( this );
      var cnt = $( this ).contents();
      $( this ).find(".day-wrap").replaceWith(cnt);
      $this.wrapInner( "<div class='day-wrap'></div>" );
    } );
  }

  var debouncedWrap = wrap_day_headers.debounce(500);



  function infoMap() {
    console.log("hi");
    var styles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f1f1f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#B5DDD6"},{"visibility":"on"}]}];
    var mapOptions = {
      center: {lat: 49.021753, lng: -122.807508},
      zoom: 14,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: styles,
      scrollwheel: false,
      mapTypeControl: false,
      panControl: false,
      panControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      streetViewControl: false,
      zoomControl: false,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.LARGE,
        position: google.maps.ControlPosition.RIGHT_TOP
      },
    };
    var map = new google.maps.Map(document.getElementById("infomap"),
      mapOptions);
    var url = site.theme_path + '/assets/images/map-marker.png';
    var image = {
        url: url,
        size: new google.maps.Size(38,64), // the orignal size
        scaledSize: new google.maps.Size(19,32),
        anchor: new google.maps.Point(9,32),
    };
    var myLatLng = new google.maps.LatLng(49.021753,-122.807508);
    var marker = new google.maps.Marker({
      position: myLatLng,
      map: map,
      icon: image,
      url: "https://www.google.com/maps/place/Tourism+White+Rock+Waterfront+Centre/@49.0217528,-122.8096969,14z/data=!3m1!4b1!4m5!3m4!1s0x5485dba9cc234ead:0x5f6fe3a24cc06e74!8m2!3d49.0217528!4d-122.8075082?hl=en"
    });
    google.maps.event.addListener(marker, 'click', function() {
      window.open(marker.url,'_blank');
    });
  }

  var faststream = "http://207.194.236.115/record/current.jpg?";
  function refreshWebCam() {
    var webCamSrc = faststream + '&nocache='+new Date().getTime();
    $('#webcam-image').attr('src', webCamSrc); // replace the current image with the new one
    // console.log(webCamSrc);
  }
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.navbar-toggle').attr({
          'role': 'button',
          'aria-controls': 'navigation',
          'aria-expanded': 'false'
        });

        var openMenu = function() {
          $('body').attr({
            'data-nav-visible': 'true'
          });
          $('.navbar-toggle').attr({
            'aria-expanded': 'true'
          });
        };

        var closeMenu = function() {
          $('body').attr({
            'data-nav-visible': 'false'
          });
          $('.navbar-toggle').attr({
            'aria-expanded': 'false'
          });
        };

        $('.navbar-toggle').on('click', function(e) {
          e.preventDefault();
          if($(this).attr('aria-expanded') === 'true'){
            closeMenu();
          }
          else{
            openMenu();
          }
        });

        // Search button
        $("._search-toggle, .close-form").click(function(a){
          a.preventDefault();
          $('body').toggleClass('form-on');
          $("#search-popup").fadeToggle();
          $(".search-field").trigger("focus");
        });

        var objPopButton    = $('#signupbtn');
        var objToolTip      = $("#signup");

        $('body').on('click','.close-popover', (e) => {
            $('#signupbtn').popover('hide');
        });     

        objPopButton.popover({
            html: true,
            placement: 'top',
            offset: 10,
            content: function() {
              return objToolTip.html();
            }
        });

        $(document).bind('gform_post_render', function(){
          var originalHeight = $('.popover').height();
          var newHeight = $('.popover').height()+2;
          $('.popover').css({ top: -newHeight });
        });


        $(document).bind('gform_confirmation_loaded', function(){
          var originalHeight = $('.popover').height();
          var newHeight = $('.popover').height()+2;

          $('.popover').css({ top: -newHeight });
          setTimeout(function() {
            $('.popover').popover('destroy');
          }, 3000);
        });

        $('.event-slideshow').slick({
          arrows: false,
          dots: true,
          autoplay: true,
          fade: true,
          infinite: true,
          autoplaySpeed: 5000,
        });
        $('.center_slider').slick({
          centerMode: true,
          centerPadding: '15px',
          variableWidth: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {

        // Slideshow options
        var anim = new Animayte();
        var slider = $('.homeslider');
        anim.init(slider);
        slider.on('init', function(event, slick){
          anim.start(slick.$slides.eq(slick.currentSlide));
        });
        slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
          slick.$slides.each(function(index) {
            if (currentSlide === index) {
              anim.start($(this));
            } else {
              anim.clear($(this));
            }
          });
        });
        slider.slick({
          fade: true,
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnHover: false,
          infinite: true,
          dots: true,
          arrows: false,
          appendDots:$(".home-slide-nav")
        });
        var scroll_start = 0;
       $(document).scroll(function() {
          scroll_start = $(this).scrollTop();
          if(scroll_start > 20) {
              $(".navbar").removeClass('home-nav');
           } else {
              $('.navbar').addClass('home-nav');
           }
       });

        var $container = $('.tile-wrapper');
        var $grid = new Isotope('.tile-wrapper',{
            masonry: {
              columnWidth: '.sizer'
            },
            itemSelector: '.tile',
            percentPosition: true,
            sortBy: 'original-order'
          });
        imagesLoaded( $grid ).on( 'progress', function() {
            // layout Masonry after each image loads
            $container.isotope('layout');
        });

        var bannerHeight = $('.navbar').height();

        $(".downer").click(function(a){
          $.scrollTo( $('#tiles').offset().top - bannerHeight, 400 );
          a.preventDefault();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_grid': {
      init: function() {
        $('.grid div>div').matchHeight();
      }
    },
    'single_wr_dine': {
      init: function() {
        $('.dineslide').slick({
          arrows: false,
          dots: true,
          autoplay: true,
          fade: true,
          infinite: true,
          autoplaySpeed: 5000,
        });
      }
    },
    'page_template_template_stay': {
      init: function() {
        $('.dineslide').slick({
          arrows: false,
          dots: true,
          autoplay: true,
          fade: true,
          infinite: true,
          autoplaySpeed: 5000,
        });
      }
    },
    'page_template_template_weather': {
      init: function() {
        $('.forecast-day>div').matchHeight();
      }
    },
    'page_template_template_infocentre': {
      init: function() {
        infoMap();
      }
    },
    'page_template_template_getting': {
      init: function() {
        infoMap();
      }
    },
    'page_template_template_camera': {
      init: function() {
        var refresh = 3; // seconds
        if ($('#webcam').length) {
          setInterval(refreshWebCam, refresh * 1000);
        }
      }
    },
    'events_archive': {
      init: function() {
        wrap_day_headers();
        $(window).on('resize', debouncedWrap);
      },
      finalize: function() {
        $('body').on( 'tribe_ev_ajaxSuccess', function() {
          console.log('coleslaw');
        });
      }
    },
    'blog': {
      init: function() {
        var $container = $('.posts-wrap');
        var $grid = new Isotope('.posts-wrap',{
            masonry: {
              columnWidth: '.sizer'
            },
            itemSelector: '.hentry',
            percentPosition: true,
            sortBy: 'original-order'
        });
        
        imagesLoaded( $grid ).on( 'progress', function() {
            // layout Masonry after each image loads
            $container.isotope('layout');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
